import { ITrackerPath } from "../interfaces/User/ITrackerPath";
import { IDefaultLinkItem } from "../interfaces/IDefaultLinkItem";

export const VideojetConnectRemoteService = "VideojetConnect™ Remote Service";
export const VideojetConnectExchange = "VideojetConnect™ Exchange";
export const VideojetConnectInsight = "VideojetConnect™ Insight";
export const VideojetConnectInspect = "VideojetConnect™ Inspect";
export const VideojetConnectDesign = "VideojetConnect™ Design";

export const vrsOperationsSite = {
  id: "VrsInternal", // We use this company for videojet global access
  companyId: "Videojet", // We use this site id for videojet global access
  title: "Operation Site",
  contactEmail: "husambaki@gmail.com",
  notificationsDelay: 10,
  notificationsEnabled: false,
  subscriptionStatus: "silver",
  tz: "UTC",
};

export const DEFAULT_TIME_ZONE = "UTC";
export const SHORT_AUTO_CLOSE_TIME = 5000; // 2 secs.
export const MULTIPLE_SELECTOR_TRUNCATION_LENGTH = 85;
export const MINIMUM_ROLE_TITLE_LENGTH = 3;
export const MINIMUM_ROLE_DESCRIPTION_LENGTH = 3;
export const hideWorkingInProgress = true;
export const DEBOUNCED_SEARCH_DELAY = 500;
export const TRACKER_DEBOUNCED_SEARCH_DELAY = 1000;
export const SMALL_NUMBER_OF_ROWS = 4;
export const KEYCODE_LENGTH = 6;
export const ONE_DAY_MILLISECONDS = 86400000;
export const SEVEN_DAYS_MILLISECONDS = 60480000000;
export const LANGUAGE_REFRESH_MINUTES = 30;
export const PROFILE_REFRESH_MINUTES = 2;
export const EXTERNAL_CALL_TRY_LONG_DELAY = 5000; // 5 second delay for re-try
export const EXTERNAL_CALL_TRY_DELAY = 2000; // 2 second delay for re-try

export const MAXIMUM_ALLOWED_DATA_TAG_SELECTIONS = 25;
export const ACTIVE_IMPORT_SIZE = 100;

export const BASE_DISPLAY_UNIT = "Base";
export const METRIC_DISPLAY_UNIT = "Metric";
export const IMPERIAL_DISPLAY_UNIT = "Imperial";

export const ReportTypeLocal = "local";
export const ReportTypeGlobal = "global";

export const YesValue = "Yes";
export const NoValue = "No";

export const API_MODULE_NAME = "api";

export const licensedModuleInfo = {
  design: { name: "Design" },
  inspect: { name: "Inspect" },
  intelligence: { name: "Intelligence" },
  orderexe: { name: "Order Execution" },
  beta: { name: "Beta" },
  vrs: { name: "vrs" },
  api: { name: API_MODULE_NAME },
};

export const iFrameExternalLinks = [];

export const reportingQueries = [
  "consumablesReportData",
  "cellularConnectivityData",
  "usageData",
  "performanceReportData",
  "unitRequiringAttentionData",
  "printerDataDownload",
  "StatusSnapshot",
];


export const wholeRow: any = { lg: 12, md: 12, sm: 12, xs: 12 };

export const LINE_IN_ONCE = 7;
export const LINE_FETCH_DELAY = 100; // ms
export const LINE_STATE_BAR_REFRESH_DELAY = 500; // ms

export const wide4kLayoutBreakPoint = 3000;
export const vert4kLayoutBreakPoint = 2000;

export const MINIMUM_SEARCH_TERM_LENGTH = 3;
export const MAX_NUMBER_OF_ITEM_ALLOWED_IN_CACHE = 20;
export const SEARCH_RESULT_PAGE_SIZE = 25;

export const PAGE_SIZE = 50;
export const SMALL_PAGE_SIZE = 10;
export const REPORT_PAGE_SIZE = 20;
export const REPORT_DOWNLOAD_PAGE_SIZE = 20;
export const REPORT_DOWNLOAD_INITIAL_PROCESSING_PAGE_SIZE = 2000;
export const REPORT_DOWNLOAD_PROCESSING_PAGE_SIZE = 2000;

export const STANDARD_PAGE_SIZES = [10, 25, 50, 100, 250];
export const UM_VCCM = "VCCM";
export const TOTAL_TRIAL_FOR_SITE_LOAD = 5;
export const PASSWORD_EXPIRATION_DAY = 7;
export const MILLISECONDS_PER_MINUTE = 60000;
export const MINUTES_PER_HOUR = 60;
export const SECOND_PER_MINUTE = 60;
export const HOURS_PER_DAY = 24;
export const MINUTES_PER_DAY = HOURS_PER_DAY * MINUTES_PER_HOUR;
export const SECONDS_PER_DAY = MINUTES_PER_DAY * SECOND_PER_MINUTE;
export const englishUsLocale = "en-us";
export const ALLOW_ONLY_SUPER_USER_DELETION_OF_USERS = true;
export const DEVICE_REFRESH_MILLISECONDS = 65000;
export const OUTAGE_CHECK_MILLISECONDS = 600000; // 10 mins
export const MAIN_OUTAGE_ID = 1;
export const DATA_TAG_IMPORT_TYPE = 0;
export const EVENT_IMPORT_TYPE = 1;
export const DEFAULT_DECIMAL_VALUE = 3;
export const supportedLocals = [
  englishUsLocale,
  "de-de",
  "fr-fr",
  "nl-bl",
  "es-es",
  "it-it",
  "pt-pt",
  "tr-tr",
];


export const EventTypeMap = {
  Type1: "info",
  Type2: "warning",
  Type3: "fault",
  Type4: "enable",
  Type5: "extra",
  Type6: "param changed",
  Type7: "error 1",
  Type8: "error 2",
  Type9: "maintenance",
};

export const EventReverseTypeMap = {
  info: 1,
  warning: 2,
  fault: 3,
  enable: 4,
  extra: 5,
  "param changed": 6,
  "error 1": 7,
  "error 2": 8,
  maintenance: 9,
};

export const VRS_ADMINISTRATOR_ACCESS_LEVEL_ID =
  "f41756b2-34fa-40c3-b52b-c74d7bdcc3aa";
export const VRS_MAINTENANCE_ACCESS_LEVEL_ID =
  "b0cfed72-dcac-4a1e-b352-9bf58dcf4f3d";
export const VRS_USER_ACCESS_LEVEL_ID = "fece8c01-275e-437d-8eca-debc32e99112";

export const VRS_VIDEOJET_ACCESS_LEVEL_ID =
  "a3a91b76-7a31-4f00-aa5a-7f4e550ebca3";
export const VRS_CSE_ACCESS_LEVEL_ID = "e0681a2c-9a92-41ed-b56c-dd0d78d27ea6";

export const VRS_VIDEOJET_SERVICE_ACCESS_LEVEL_ID =
  "0c9a7962-b348-4e0c-a49d-33245a23b468";

export const VRS_VIDEOJET_CONTACT_ADMIN_ACCESS_LEVEL_ID =
  "6e343924-50df-48fc-b5fc-1de477233fb3";

export const DEFAULT_SNAPSHOT_CHART_TYPE = "r";

export const PERFORMANCE_REPORT_ID = 1;
export const SNAPSHOT_REPORT_ID = 3;
export const CONSUMABLES_REPORT_ID = 11;

export const vrsTrackerPages: ITrackerPath[] = [
  {
    pathname: "/vrs/sitedashboard",
    action: "Accessed Locate Printer",
    companyIdRequired: true,
  },
  {
    pathname: "/vrs/device",
    action: "Accessed Printer Dashboard",
    companyIdRequired: true,
  },
  {
    pathname: "/vrs/remoteaccess/device",
    action:
      "RemoteAccess/Company/[companyId]/Plant/[plantId]/Printer/[printerId]",
    companyIdRequired: true,
  },
  {
    pathname: "/vrs/consumablesreport",
    action: "Consumables/Company/[companyId]/Plant/[plantId]",
    companyIdRequired: true,
  },
  {
    pathname: "/vrs/performancereport",
    action: "Performance/Company/[companyId]/Plant/[plantId]",
  },
  {
    pathname: "/vrs/snapshotreport",
    action: "Snapshot/Company/[companyId]/Plant/[plantId]/Printer/[printerId]",
  },
];

export const DayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const StatusColorOrderMap = {
  red: 1,
  yellow: 2,
  green: 3,
  blue: 4,
  res_red: 5, // we do not expect res_red now, maybe in future it might happen
  res_yellow: 6,
  res_green: 7,
  res_blue: 8,
  grey: 9,
  res_grey: 10,
  orange: 11,
  res_orange: 12,
};

export const RoleNameMap = {
  PrinterSnapshotReport: {
    visible: true,
    order: 0,
    name: "Printer Snapshot",
  },
  PrinterPerformance: {
    visible: true,
    order: 1,
    name: "Printer Performance",
  },
  DataDownload: {
    visible: true,
    order: 2,
    name: "Data Download",
  },
  "RapidRecover™Report": {
    visible: true,
    order: 3,
    name: "Rapid Recovery",
  },
  ConsumablesReport: {
    visible: true,
    order: 4,
    name: "Consumable Usage",
  },
  UnitsRequiringAttentionReport: {
    visible: true,
    order: 5,
    name: "Printers Needing Attention",
  },
  VRSUsageReport: {
    visible: true,
    order: 6,
    name: "VRS Usage",
  },
  ActiveConnectedReport: {
    visible: true,
    order: 7,
    name: "Connected Devices",
  },
  CellularConnectivityReport: {
    visible: true,
    order: 8,
    name: "Cellular Connections",
  },
};

export const TimeScaleReleasePlan: Array<IDefaultLinkItem> = [
  {
    defaultTarget: "ts",
    path: "/home",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/sitedashboard",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/devicemanagement",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/favorites",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/device",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/sitemap",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/availability",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/production",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/servicedashboard",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/snapshotreport",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/performancereport",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/datadownload",
  },
  {
    defaultTarget: "sql",
    path: "/vrs/rapidrecoveryreport",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/consumablesreport",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/unitrequiringattentionreport",
  },
  {
    defaultTarget: "sql",
    path: "/vrs/usagereport",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/users",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/datatags",
  },
  {
    defaultTarget: "ts",
    path: "/vrs/events",
  },
  {
    defaultTarget: "ts",
    path: "/company/users"
  },
  {
    defaultTarget: "ts",
    path: "/sites"
  },
  {
    defaultTarget:"ts",
    path: "/logmanagement"
  },
  {
    defaultTarget:"ts",
    path: "/resmanagement"
  },
  {
    defaultTarget:"ts",
    path: "/alertmanagement"
  },
  {
    defaultTarget:"ts",
    path: "/vrs/sitemanagement"
  },
  {
    defaultTarget:"ts",
    path: "/vrs/companymanagement"
  },
];

export const MonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const ExportSelectionTypes = {
  None: -1,
  AllItems: 0,
  EmptyOnlyItems: 1,
  NonEmptyOnlyItems: 2,
  InvalidOnlyItems: 3,
  ActiveOnlyItems: 4,
  InactiveOnlyItems: 5,
  SearchedOnlyItems: 6,
};

export const  DesignEditorUrlDev = 'http://localhost:9001/';
export const  DesignEditorUrl = 'editor.html';
export const  ActiveDesignEditor: "release" | "dev" = 'release'; // dev or release
