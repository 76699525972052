const config = {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    BUCKET: 'vjt-notes-app-upload',
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://37ztjwvfw9.execute-api.us-west-2.amazonaws.com/dev',
    ASSESSMENTS_URL: 'https://24fpy7nqd1.execute-api.us-west-2.amazonaws.com/prod',
    COMPANIES_URL: 'https://t3ej6w26j1.execute-api.us-west-2.amazonaws.com/prod',
    DASHBOARDS_URL: 'https://yj1h83qoc5.execute-api.us-west-2.amazonaws.com/prod',
    DOWNTIMES_REASONS_URL: 'https://p51m7is822.execute-api.us-west-2.amazonaws.com/prod',
    DOWNTIMES_EVENTS_URL: 'https://uyv2f789de.execute-api.us-west-2.amazonaws.com/prod',
    INSPECTIONS_URL: 'https://24fpy7nqd1.execute-api.us-west-2.amazonaws.com/prod',
    LINES_URL: 'https://k6edix8x3g.execute-api.us-west-2.amazonaws.com/prod',
    OEE_URL: 'https://q770eit1ee.execute-api.us-west-2.amazonaws.com/prod',
    PRODUCTS_URL: 'https://rcph0d7b00.execute-api.us-west-2.amazonaws.com/prod',
    ROLES_URL: 'https://7q4po6dq09.execute-api.us-west-2.amazonaws.com/prod',
    SCHEDULES_URL: 'https://1ueb62j2dj.execute-api.us-west-2.amazonaws.com/prod',
    SHIFT_URL: 'https://xunc5rypfe.execute-api.us-west-2.amazonaws.com/prod',
    SITES_URL: 'https://vd2v4a8mzb.execute-api.us-west-2.amazonaws.com/prod',
    USERS_URL: 'https://f8lvibywx5.execute-api.us-west-2.amazonaws.com/prod',
    NOTIFICATION_URL: 'https://4css48ez03.execute-api.us-west-2.amazonaws.com/prod',
    CIRRUS_URL: 'https://www.cirrusprint.net',
    TEMPLATE_DESIGN_API_URL: 'https://localhost:5001',
    SALESFORCE_URL: 'https://videojet.my.salesforce.com/',
    VRS_URL: 'https://vrs.videojet.com',
    VCCM_HELP_URL: 'https://help.videojetcloud.com/',
    API_APP_URL: 'api.videojetcloud.com',
    NGINX_HTTP_SERVER: 'http-print.videojetcloud.com',
    NGINX_VNC_SERVER: 'vnc-print.videojetcloud.com',
    NGINX_SSH_PORT: '443',
    NGINX_COOKIE_DOMAIN: 'videojetcloud.com',
    API_BASE_URL: 'https://j75glhsb42.execute-api.us-west-2.amazonaws.com/prod'
  },
  appSync: {
    REGION: 'us-west-2',
    VRS_APPSYNC_URL: 'https://ldpcmybwazf2zc4tyt5dyuhl4q.appsync-api.us-west-2.amazonaws.com/graphql',
    RES_APPSYNC_URL: 'https://tiler4tczvaolejcipikvrnhmm.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_DEVICE_URL: 'https://ldpcmybwazf2zc4tyt5dyuhl4q.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_REPORT_URL: 'https://z4on7s75qfdtnpsdt4gb5kuds4.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_RES_URL: 'https://6quhtkarvrekne4hpqhxkiqyeq.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_COMPANY_URL: 'https://h5qom3gkyzaznlticxflybenyy.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_SITE_URL: 'https://433m4ngqx5gcjj2urag7n6glw4.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_SYSTEM_URL: 'https://a2j43rb27ncnzaz3viqkmi6m64.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_USER_URL: 'https://5i6nalgesrhqpgbigqbcmlyjti.appsync-api.us-west-2.amazonaws.com/graphql',
    VRS_APPSYNC_NOTIFICATION_URL: 'https://ytejpimnl5hxnm24iakhfxhim4.appsync-api.us-west-2.amazonaws.com/graphql',
  },

  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_4Ymwhh3Lz',
    APP_CLIENT_ID: '64nbo0qntkj4giu4vt3f18gcbv',
    IDENTITY_POOL_ID: 'us-west-2:3201550e-e47c-44cf-835f-a63786d4f28d',
    OATH_TOKEN_URL: 'https://signup.videojetcloud.com/oauth2',
    SSO_API: 'https://vjcloudservices.auth.us-west-2.amazoncognito.com/authorize?identity_provider=VJHUBADFS&response_type=token&client_id=3aarms5h44ejke940nobm4srso&redirect_uri=https://vccm.dev.videojetcloud.com/showJWT',
  },
  settings: {
    CLIENT_VERSION: '57d22762ac81f7f88368bd6d45bcb9d6fd67b75f',
    UM_MODULE: 'VCCM',
    MF_PROJECT: 'a7640cb1-3c31-4a29-a815-105cfd0824d9',
    VRS_VERSION: '2.9.95'
  },
};

export default config;
