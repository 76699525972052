import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import {
  GridColDef,
  GridRowsProp
} from '@mui/x-data-grid-pro';
import toastr
  from 'toastr';
import classNames
  from 'classnames';
import EditIcon
  from '@mui/icons-material/Edit';
import DeleteIcon
  from '@mui/icons-material/Delete';
import ArrowUpwardIcon
  from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon
  from '@mui/icons-material/ArrowDownward';

import StyledGridCell
  from '../../../components/Basic/StyledGridCell/StyledGridCell';
import StyledContentCard
  from '../../../components/Basic/StyledContentCard/StyledContentCard';
import BarStyledGrid
  from '../../../components/Basic/BarStyledGrid/BarStyledGrid';
import SiteSelectionComponent
  from '../../../components/SiteSelectionComponent/SiteSelectionComponent';
import {
  useSetTitleAction
} from '../../../actions/useActions/useSetTitleAction/useSetTitleAction';
import {
  useAppCompanyState,
  useAppGlobalState,
  useVrsTranslationState
} from '../../../context/AppContext/AppContext';
import {
  CogIcon
} from '../../../components/CogIcon/CogIcon';
import {
  StyledDataGrid
} from '../../../components/StyledDataGrid/StyledDataGrid';
import {
  ConfirmDialog
} from '../../../components/ConfirmDialog/ConfirmDialog';
import {
  VrsEditTagDialog
} from './VrsEditTagDialog';
import useCommonStyledDataGridProps
  from '../../../hooks/useCommonStyledDataGridProps';
import {
  useSaveTag,
  useSaveTagPriorities
} from '../../../hooks/useSaveTag';
import {
  ISaveTagInputType
} from '../../../interfaces/Tags/ISaveTagInputType';
import {
  IDeleteTagInputType
} from '../../../interfaces/Tags/IDeleteTagInputType';
import {
  ProgressIndicator
} from '../../../components/ProgressIndicator/ProgressIndicator';
import {
  useFetchTags
} from '../../../hooks/useFetchTags';
import {
  useFetchDevices
} from '../../../hooks/useFetchDevices';
import useSiteTagsStyles
  from './useSiteTagsStyles';
import ActionContainer
  from '../../../components/Basic/ActionContainer/ActionContainer';
import {
  ActionButton
} from '../../../components/ActionButton/ActionButton';
import InfoIcon
  from '@mui/icons-material/Info';
import useFetchDeviceTags
  from '../../../hooks/useFetchDeviceTags';
import {
  IDeviceTag
} from '../../../interfaces/Tags/IDeviceTag';
import Accordion
  from '@mui/material/Accordion';
import ExpandMoreIcon
  from '@mui/icons-material/ExpandMore';
import AccordionDetails
  from '@mui/material/AccordionDetails';
import useSaveDeviceTags
  from '../../../hooks/useSaveDeviceTags';
import SiteTag
  from '../../../components/SiteTag/SiteTag';
import {
  IUserTag
} from '../../../interfaces/Tags/IUserTag';
import useFetchUserTags
  from "../../../hooks/useFetchUserTags";
import useSaveUserTags
  from "../../../hooks/useSaveUserTags";
import useFetchUsers
  from "../../../hooks/useFetchUsers";
import UserHelper
  from "../../../helpers/UserHelper";

type AccordionOption = {
  value: string;
  text: string;
};

const SiteTagsPage = () => {
  const classes = useSiteTagsStyles();
  const { isAdmin, isVJInternalUser } = useAppGlobalState();
  const setTitle = useSetTitleAction();
  const { _T } = useVrsTranslationState();
  const { selectedSiteIdForCompany, selectedCompanyId } = useAppCompanyState();
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [tagToDelete, setTagToDelete] = useState<IDeleteTagInputType | null>(null);

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [deviceOptions, setDeviceOptions] = useState<AccordionOption[]>([]);
  const [userOptions, setUserOptions] = useState<AccordionOption[]>([]);

  const { data: users, isFetched: usersLoaded, isLoading: usersLoading } = useFetchUsers(
    selectedCompanyId,
    ["UserId", "EmailAddress", "CompanyId", "PlantIDs", "Disabled"],
    { enabled: !!selectedCompanyId }
  ) as { data: any[], isLoading: boolean, isFetched: boolean };

  const isExternalAdmin = useMemo(() => !!UserHelper.CanWithField('vrs', 'authModule', 'Administrator'), []);

  useEffect(() => {
    if (users) {
      const opts = users
        .filter((user: { CompanyId: string, PlantIDs: string, Disabled: boolean }) =>
          user.CompanyId === selectedCompanyId &&
          user.PlantIDs.split(',').includes(selectedSiteIdForCompany) &&
          !user.Disabled
        )
        .map((user: { UserId: string, EmailAddress: string }) => ({
          value: user.UserId,
          text: user.EmailAddress,
        }));
      setUserOptions(opts);
      console.log('users', users, opts, selectedCompanyId, selectedSiteIdForCompany);
    }
  }, [users, selectedSiteIdForCompany, selectedCompanyId]);



  const [refreshIndex, setRefreshIndex] = useState(0);

  const { data: devices, isFetched: devicesLoaded, isLoading: devicesLoading } = useFetchDevices(selectedSiteIdForCompany, refreshIndex, ['Id', 'FriendlyName', 'Serial', 'Disabled'],
    { enabled: (Number(selectedSiteIdForCompany) > 0) }) as { data: any[], isFetched: boolean, isLoading: boolean };
  const { data: tags, isFetched: tagsLoaded, isLoading: tagsLoading } = useFetchTags(
    selectedSiteIdForCompany,
    ['Id', 'Name', 'NomineeId', 'Color', 'CreatedDt', 'ModifiedDt', 'SiteId', 'Priority' ],
    { enabled: (Number(selectedSiteIdForCompany) > 0)}
  ) as { data: ISaveTagInputType[], isLoading: boolean, isFetched: boolean};

  const tagIds = useMemo(() => (tags || []).map(tag => tag?.Id || 0), [tags]);

  const { data: fetchedDeviceTags, isFetched: deviceTagsLoaded, isLoading: deviceTagsLoading } = useFetchDeviceTags(
    tagIds,
    { enabled: tagsLoaded && tagIds.length > 0 }
  ) as { data: IDeviceTag[], isLoading: boolean, isFetched: boolean};

  const { data: fetchedUserTags, isFetched: userTagsLoaded, isLoading: userTagsLoading } = useFetchUserTags(
    tagIds,
    { enabled: tagsLoaded && tagIds.length > 0 }
  ) as { data: IUserTag[], isLoading: boolean, isFetched: boolean };

  const deviceTags = fetchedDeviceTags ?? [];
  const userTags = fetchedUserTags ?? [];

  const saveTagMutation = useSaveTag();
  const saveDeviceTagsMutation = useSaveDeviceTags();
  const saveUserTagsMutation = useSaveUserTags();
  const savePrioritiesMutation = useSaveTagPriorities();
  const [originalTagsState, setOriginalTagsState] = useState<ISaveTagInputType[]>([]);
  const [tagsState, setTagsState] = useState<ISaveTagInputType[]>([]);

  const getMaxPriority = (tags: ISaveTagInputType[]) => {
    return !(tags && tags?.length)
      ? 0
      : tags.reduce((max, tag) =>
          tag?.Priority && tag?.Priority > max
            ? tag.Priority
            : max,
        0);
  };

  const defaultTag : ISaveTagInputType = useMemo(() => {
    return {
      NomineeId: '',
      Color: '#000000',
      Name: '',
      SiteId: Number(selectedSiteIdForCompany),
      Priority: getMaxPriority(tags) + 1
    };
  }, [selectedSiteIdForCompany, tags]);

  const [tagToEdit, setTagToEdit] = useState(defaultTag);

  const sortTagsByPriority = (tags: ISaveTagInputType[]) => {
    return tags ? [...tags].sort((a, b) => (a?.Priority || 0) - (b?.Priority || 0)) : [];
  };


  useEffect(() => {
    if (tags) {
      setHasUnsavedChanges(false);
      setOriginalTagsState(tags);
      setTagsState(sortTagsByPriority(tags));
    }
  }, [tags]);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const moveTag = (sourceIndex, destIndex) => {
    setTagsState(prevTags => {
      const newTags = [...prevTags];
      const [removed] = newTags.splice(sourceIndex, 1);
      newTags.splice(destIndex, 0, removed);
      setHasUnsavedChanges(true);
      return sortTagsByPriority(newTags.map((tag, idx) => ({ ...tag, Priority: idx + 1 })));
    });
  };
  const moveTagOneRow = (tagId: number, direction: 'up' | 'down') => {
    setTagsState(prevTags => {
      const index = prevTags.findIndex(tag => tag.Id === tagId);
      if (index === -1) return prevTags;

      const newTags = [...prevTags];
      if (direction === 'up' && index > 0) {
        [newTags[index - 1], newTags[index]] = [newTags[index], newTags[index - 1]];
      } else if (direction === 'down' && index < newTags.length - 1) {
        [newTags[index], newTags[index + 1]] = [newTags[index + 1], newTags[index]];
      }
      setHasUnsavedChanges(true);
      return sortTagsByPriority(newTags.map((tag, idx) => ({ ...tag, Priority: idx + 1 })));
    });
  };

  useEffect(() => {
    if (devices) {
      const uniqueDeviceIds = new Set<string>();
      const opts = devices
        .filter(d => !d.Disabled)
        .map((device: {
        Id: string;
        FriendlyName: string;
        Serial: string;
      }) => ({ value: device.Id, text: `${device.FriendlyName} (${device.Serial})` })).filter(device => {
        if (uniqueDeviceIds.has(device.text)) {
          return false;
        } else {
          uniqueDeviceIds.add(device.text);
          return true;
        }
      });
      setDeviceOptions(opts);
    }
  }, [devices]);

  const handleAddTag = useCallback(() => {
    setTagToEdit(defaultTag);
    setEditDialogOpen(true);
  }, [defaultTag]);

  const handleEditClick = useCallback((tag: { id: string }) => {
    const foundTag = tags.find(t => tag && t && t.Id === Number(tag.id)) as ISaveTagInputType | undefined;
    setTagToEdit(foundTag || defaultTag);
    setEditDialogOpen(true);
  }, [tags, defaultTag]);

  const handleEditDialogClose = useCallback(() => {
    setEditDialogOpen(false);
    setTagToEdit(defaultTag);
  }, [defaultTag]);

  const handleDeleteClick = useCallback((tagId) => {
    setTagToDelete({ Id: tagId, SiteId: Number(selectedSiteIdForCompany) });
    setConfirmDialogOpen(true);
  }, [selectedSiteIdForCompany]);

  const handleConfirmDialogClose = useCallback((confirmed) => {
    if (confirmed && tagToDelete) {
      saveTagMutation.mutate(tagToDelete, {
        onSuccess: () => toastr.success(_T('Deleted tag')),
        onError: () => toastr.error(_T('Failed to delete tag')),
      });
    }
    setConfirmDialogOpen(false);
    setTagToDelete(null);
  }, [tagToDelete, saveTagMutation, _T]);

  useEffect(() => {
    setTitle(
      _T('Site Tags'),
      CogIcon,
      {
        ignoreItself: true,
        parents: [{ id: _T('Configuration'), textOnly: true }, { id: _T('Site Tags'), textOnly: true }],
      },
      true
    );
  }, [setTitle, _T]);

  const createAccordionColumn = (field: string, headerName: string, options: AccordionOption[], tags: (IDeviceTag | IUserTag)[], classes: any, _T: (key: string) => string) => ({
    field,
    headerName: _T(headerName),
    flex: 2.5,
    cellClassName: classes.centerAlign,
    sortable: false,
    renderCell: (params) => {
      const { id } = params.row;
      const matchedItems = options.filter(option =>
        tags.some(tag => tag.TagId === id && (
          ('DeviceId' in tag && tag.DeviceId === option.value) ||
          ('UserId' in tag && tag.UserId === option.value)
        ))
      );

      const itemCount = matchedItems.length;

      if (itemCount === 0) {
        return <div/>;
      }

      if (itemCount === 1) {
        return (
          <Typography className={classes.taggedAccordionWithBorder} style={{ borderColor: params.row.Color }}>
            {matchedItems[0].text}
          </Typography>
        );
      }

      return (
        <Accordion className={classes.accordion} style={{ border: `2px solid ${params.row.Color}` }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.accordionSummary}
          >
            <Typography>{`${matchedItems.length} ${_T(headerName)}`}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography component="div">
              <ul>
                {matchedItems.map((item, index) => (
                  <li className={classes.truncatedText} key={`${item.value}-${index}`}>{item.text}</li>
                ))}
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
      );
    }
  });

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'Priority',
      headerName: _T('Priority'),
      type: 'number',
      flex: .5,
      sortable: false,
      renderCell: (params) => <Box  height="100%" className={classes.centerAlign} >
        {params.value}
      </Box>,
    },
    { field: 'Name', headerName: _T('Name'), type: 'string', flex: 1, cellClassName: classes.centerAlign, sortable: false },
    { field: 'NominalDevice',
      headerName: _T('Primary Device'),
      type: 'string',
      flex: 1.5,
      cellClassName: classes.centerAlign,
      sortable: false,
      renderHeader: () => (
        <div className={classes.nominalDeviceHeader}>
          <span>{_T('Primary Device')}</span>
          <Tooltip
            title={_T('The leader device for all devices tagged with this tag.')}
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10], // Adjust the second value to increase the distance
                  },
                },
              ],
            }}
          >
            <InfoIcon className={classes.tooltipOffset} />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'Color',
      headerName: _T('Color'),
      type: 'string',
      flex: 1,
      cellClassName: classes.centerAlign,
      sortable: false,
      renderCell: (params) => <SiteTag label={params.value} color={params.value} accented={true} />,
    },
    createAccordionColumn('deviceCount', 'Tagged Devices', deviceOptions, deviceTags, classes, _T),
    createAccordionColumn('userCount', 'Tagged Users', userOptions, userTags, classes, _T),
    {
      field: 'actions',
      headerName: _T('Actions'),
      flex: 2,
      cellClassName: classes.centerAlign,
      sortable: false,
      pinnable: false,
      disableReorder: true,
      disableExport: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isUpDisabled = !isExternalAdmin && !isAdmin && !isVJInternalUser || params.row.Priority === 1;
        const isDownDisabled = !isExternalAdmin && !isAdmin && !isVJInternalUser || params.row.Priority === params.api.getRowsCount();
        const UpButton = <ActionButton
          className={classes.actionButton}
          onClick={() => moveTagOneRow(params.row.id, 'up')}
          disabled={isUpDisabled}
        >
          <ArrowUpwardIcon />
        </ActionButton>;

        const DownButton =  <ActionButton
          className={classes.actionButton}
          onClick={() => moveTagOneRow(params.row.id, 'down')}
          disabled={isDownDisabled}
        >
          <ArrowDownwardIcon />
        </ActionButton>;

        const DeleteButton = <ActionButton
          className={classes.actionButton}
          onClick={() => handleDeleteClick(params.row.id)}
          disabled={!isAdmin && !isVJInternalUser && !isExternalAdmin}>
          <DeleteIcon />
        </ActionButton>;
        return (
          <Box display="flex" alignItems="center" padding={'.5rem'}>
            <Box mr={2}>
              <ActionContainer>
                {isUpDisabled ? UpButton :<Tooltip title="Move Up">
                  {UpButton}
                </Tooltip>
                }
                {isDownDisabled ? DownButton : <Tooltip title="Move Down">{DownButton}</Tooltip>}
              </ActionContainer>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box ml={2}>
              <ActionContainer>
                <Tooltip title="Edit">
                  <ActionButton className={classes.actionButton} onClick={() => handleEditClick(params.row)}>
                    <EditIcon />
                  </ActionButton>
                </Tooltip>
                {!isAdmin && !isVJInternalUser && !isExternalAdmin ? DeleteButton : <Tooltip title="Delete">{DeleteButton}</Tooltip>}
              </ActionContainer>
            </Box>
          </Box>
        );
      },
    },
  ], [classes.centerAlign, deviceTags, deviceOptions, isAdmin, isVJInternalUser]);

  const rows: GridRowsProp = useMemo(() =>
    !tagsState
      ? []
      : tagsState.filter(t => t).map((t) => {
          const device = deviceOptions.find(d => t && d.value === t?.NomineeId);
          return ({
            id: t.Id,
            Name: t.Name,
            NominalDevice: device ? device.text : t?.NomineeId,
            Color: t.Color,
            Priority: t.Priority,
            __reorder__: `${_T('Priority')}: ${t.Priority}, ${_T('Name')}: ${t.Name}`
          });
        }
      ), [tagsState, deviceOptions]);

  const gridProps = useCommonStyledDataGridProps(columns, rows);

  const handleSaveTag = useCallback((tag: ISaveTagInputType, taggedDevices: string[], taggedUsers: string[]) => {
  const existingTag = tags.find(t => t && tag && t.Id === tag?.Id);

  // Check if the tag has changed
  const normalize = (value: string | null | undefined) => value ?? '';

  const tagChanged =
    !existingTag ||
    (normalize(existingTag.Name) !== normalize(tag.Name) ||
      normalize(existingTag.Color) !== normalize(tag.Color) ||
      normalize(existingTag.NomineeId) !== normalize(tag.NomineeId));
  // determine if we need to update deviceTags (add or remove)
  const assignedDeviceTags = deviceTags.filter((dt) => dt.TagId === tag.Id);
  const noDeviceChanges =
    assignedDeviceTags.length === taggedDevices.length &&
    assignedDeviceTags.every((et) => taggedDevices.some((td) => td === et.DeviceId));
  const assignedUserTags = userTags.filter((ut) => ut.TagId === tag.Id);
  const noUserChanges =
    assignedUserTags.length === taggedUsers.length &&
    assignedUserTags.every((et) => taggedUsers.some((ut) => ut === et.UserId));

  if (tagChanged || !noDeviceChanges || !noUserChanges) {
    if (tagChanged) {
      saveTagMutation.mutate(tag, {
        onSuccess: (tagId) => {
          if (!noDeviceChanges) {
            saveDeviceTagsMutation.mutate({ tagId: tagId, deviceTags: taggedDevices }, { onError: () => toastr.error(_T('Failed to save device tags')) });
          }
          if (!noUserChanges) {
            saveUserTagsMutation.mutate({ tagId: tagId, userTags: taggedUsers }, { onError: () => toastr.error(_T('Failed to save user tags')) });
          }
          toastr.success(_T('Tag saved successfully'));
          setTagToEdit(defaultTag);
          setRefreshIndex(r => r + 1);
        },
        onError: () => toastr.error(_T('Failed to save tag')),
      });
    } else {
      if (!noDeviceChanges) {
        saveDeviceTagsMutation.mutate({ tagId: tag.Id || 0, deviceTags: taggedDevices }, { onError: () => toastr.error(_T('Failed to save device tags')) });
      }
      if (!noUserChanges) {
        saveUserTagsMutation.mutate({ tagId: tag.Id || 0, userTags: taggedUsers }, { onError: () => toastr.error(_T('Failed to save user tags')) });
      }
      toastr.success(_T('Tag saved successfully'));
      setRefreshIndex(r => r + 1);
    }
  } else {
    toastr.info(_T('No changes detected'));
  }

  setTagToEdit(defaultTag);
}, [tags, deviceTags, userTags, saveTagMutation, saveDeviceTagsMutation, saveUserTagsMutation, _T, defaultTag]);

  const handleSavePriorities = useCallback(() => {
  const modifiedTags = tagsState.filter(tag => {
    const originalTag = originalTagsState.find(t => t.Id === tag.Id);
    return originalTag && originalTag.Priority !== tag.Priority;
  });

  if (modifiedTags.length > 0) {
    savePrioritiesMutation.mutate({
      siteId: Number(selectedSiteIdForCompany),
      priorities: modifiedTags.map(tag => ({ id: tag.Id as number, priority: tag.Priority as number }))
    }, {
      onError: () => toastr.error(_T('Failed to save tags')),
      onSuccess: () => toastr.success(_T('Tags saved successfully'))
    });
  } else {
    toastr.info(_T('No changes detected'));
  }
}, [savePrioritiesMutation, selectedSiteIdForCompany, tagsState, originalTagsState, _T]);

  const handleResetPriorities = useCallback(() => {
    setTagsState(sortTagsByPriority(originalTagsState));
    setHasUnsavedChanges(false);
  }, [originalTagsState]);

  return (
    <div className={classes.rootContainer}>
      { (Number(selectedSiteIdForCompany) > 0)
      && (devicesLoading || tagsLoading || deviceTagsLoading || userTagsLoading || usersLoading // loading data?
        || !tagsLoaded || !devicesLoaded || !usersLoaded // primary data not loaded?
        || (tagsLoaded && tags.length > 0 && !deviceTagsLoaded && !userTagsLoaded) // secondary data not loaded?
        || saveTagMutation.isLoading || savePrioritiesMutation.isLoading ) ? (
        <ProgressIndicator />
      ) : (
        <>
          <StyledContentCard classes={{ root: classes.barRoot }}>
            <BarStyledGrid>
              <StyledGridCell lg={6} md={12} sm={12} xs={12} className={classes.root}>
                <SiteSelectionComponent isDisabled={false} />
              </StyledGridCell>
            </BarStyledGrid>
          </StyledContentCard>
          <Grid item xs={12} container className={classes.gridContainer}>
            <StyledGridCell className={classNames(classes.filterContainer, classes.actionButton)}>
              <Button variant="contained" color="primary" onClick={handleAddTag} disabled={!(selectedSiteIdForCompany || isExternalAdmin || isAdmin || isVJInternalUser) || tagIds.length === 50}>
                {_T('Add Tag')}
              </Button>
            </StyledGridCell>
            <StyledGridCell className={classNames(classes.filterContainer, classes.actionButton)}>
              <Button variant="contained" color="primary" onClick={handleSavePriorities} disabled={!(selectedSiteIdForCompany || isExternalAdmin || isAdmin || isVJInternalUser) || !hasUnsavedChanges} >
                {_T('Save Priorities')}
              </Button>
            </StyledGridCell>
            <StyledGridCell className={classNames(classes.filterContainer, classes.actionButton)}>
              <Button variant="contained" color="primary" onClick={handleResetPriorities} disabled={!(selectedSiteIdForCompany || isExternalAdmin || isAdmin || isVJInternalUser) || !hasUnsavedChanges}>
                {_T('Reset Priorities')}
              </Button>
            </StyledGridCell>
          </Grid>
          { Number(selectedSiteIdForCompany) > 0 && tagsLoaded && <StyledDataGrid {...gridProps}
                                                                                  rowReordering
                                                                                  onRowOrderChange={(params) => {
                                                                                    const { oldIndex, targetIndex } = params;
                                                                                    moveTag(oldIndex, targetIndex);
                                                                                  }} />
          }
          <ConfirmDialog
            open={isConfirmDialogOpen}
            onClose={handleConfirmDialogClose}
            title={_T('Confirm Deletion')}
            content={_T('Are you sure you want to delete this tag?')}
          />
          <VrsEditTagDialog
            editMode={Boolean(tagToEdit.Id)}
            open={isEditDialogOpen}
            onClose={handleEditDialogClose}
            tag={tagToEdit}
            saveTag={handleSaveTag}
            devices={deviceOptions}
            deviceTags={deviceTags.filter(dt => dt.TagId === tagToEdit.Id)}
            users={userOptions}
            userTags={userTags.filter(ut => ut.TagId === tagToEdit.Id)}
          />
        </>
      )}
    </div>
  );
};

export default SiteTagsPage;
