import { GridSlots, GridRowHeightReturnValue } from '@mui/x-data-grid-pro';
import { GridToolbar } from '@mui/x-data-grid';
import { ProgressIndicator } from '../components/ProgressIndicator/ProgressIndicator';
import { useVrsTranslationState } from '../context/AppContext/AppContext';
import { EmptyItem } from '../components/Basic/EmptyItem/EmptyItem';

const useCommonStyledDataGridProps = (columns: any, rows: any, apiRef?: any, initialState?: any, showSearch = true) => {
  const { _T } = useVrsTranslationState();

  const emptyItem = EmptyItem({ text: _T('No Results Found') });
  const defaultInitialState = { pagination: { paginationModel: { pageSize: 50 } } };
  return {
    apiRef,
    autoHeight: true,
    columns,
    disableColumnFilter: true,
    disableColumnSelector: true,
    disableDensitySelector: true,
    disableRowSelectionOnClick: true,
    getRowClassName: (params: any) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd',
    getRowHeight: (): GridRowHeightReturnValue => 'auto',
    hideFooterPagination: !rows || rows.length <= 25,
    initialState: {...defaultInitialState, ...initialState},
    pagination: true,
    rows,
    slots: {
      loadingOverlay: ProgressIndicator as GridSlots['loadingOverlay'],
      noResultsOverlay: () => emptyItem,
      noRowsOverlay: () => emptyItem,
      toolbar: showSearch ? GridToolbar : undefined,
    },
    slotProps: showSearch ? {
      toolbar: {
        csvOptions: { disableToolbarButton: true },
        printOptions: { disableToolbarButton: true },
        showQuickFilter: true,
      },
    } : undefined,
    sx: { border: 0 },
  };
};

export default useCommonStyledDataGridProps;
